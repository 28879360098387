<template>
  <div>
    <ax-form ref="form" :formBuilder="formBuilder">
      <template slot="graphList">
        <a-button type="primary"  @click="openMap">
          网格标绘
        </a-button>
      </template>
      <template slot="gridPoint">
        <div class="gridPoint">
          <a-button type="primary"  @click="openPoint">
            地图标点
          </a-button>
          <div>
            <span>经度:</span>
            <a-input style="width: 25%" disabled placeholder="暂无数据" v-model="pointForm.Lng"></a-input>
            <span style="margin-left: 0.05rem">纬度:</span>
            <a-input style="width: 25%" disabled placeholder="暂无数据"  v-model="pointForm.Lat"></a-input>
          </div>
        </div>
      </template>
    </ax-form>
  </div>
</template>

<script>
const formList = [
  {
    label: "网格(小区)名称",
    type: "input",
    model: "name",
    options: { maxLength: 20 },
    formItem: {},
    col: { span: 24 },
    rules: [
      { required: true, message: "网格名称", placeholder: "网格(小区)名称" },
    ],
  },
  {
    label: "上级网格",
    type: "treeSelect",
    model: "pcode",
    options: { disabled: false },
    formItem: {},
    col: { span: 24 },
    // rules: [{ required: true, message: "上级网格" }],
  },
  {
    label: "详细地址",
    type: "textarea",
    model: "address",
    options: {
      minRows: 4,
      maxRows: 4,
      maxLength: 200,
      placeholder: "详细地址",
    },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: false, message: "网格名称" }],
  },
  {
    label: "网格标绘",
    type: "",
    model: "graphList",
    options: {},
    formItem: {},
    col: { span: 24 },
  },
  {
    label: "地图标点",
    type: "",
    model: "gridPoint",
    options: {},
    formItem: {},
    col: { span: 24 },
  },
];
import api from "../api";
export default {
  components: {},
  data() {
    return {
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
      disabled: false,
      pcode: "",
      pointForm: {
        Lng: "",
        Lat: "",
      },
    };
  },
  methods: {
    openMap() {
      this.$emit("openMap");
    },
    // 标点弹窗
    openPoint() {
      this.$emit("openPoint");
    },
    // 清空
    resetForm() {
      this.$refs.form.resetFields();
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree(id) {
      try {
        const res = await api.gridTree({ id: id });
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        if (!res.data.length) {
          this.$refs.form.setFormItemProp("pcode", {
            options: {
              disabled: true,
              placeholder: "上级网格",
            },
          });
        } else {
          this.$refs.form.setFormItemProp("pcode", {
            options: {
              treeData,
              allowClear: true,
              placeholder: "上级网格",
            },
            rules: [{ required: true, message: "上级网格" }],
          });
        }
      } catch (error) {
        console.error(error); // 处理错误
      }
    },

    retList() {
      this.$refs.form.form.validateFields(async (err, value) => {
        // console.log(value);
        if (err) return;
        this.$emit("retValue", value);
      });
    },
    // 查询
    async getgrid(id) {
      try {
        const res = await api.getgrid(id);
        this.$refs.form.setFieldsValue(res.data);
        this.pointForm.Lng  = res.data.longitude
        this.pointForm.Lat  = res.data.latitude
        this.$emit("retPoint", this.pointForm);
        this.$emit("retId", res.data.isDraw);
        this.getgridTree(id);
        setTimeout(() => {
          if (!res.data.pcode) {
            this.$refs.form.setFormItemProp("pcode", {
              options: {
                disabled: true,
                placeholder: "上级网格",
              },
              rules: [{ required: false, message: "网格名称" }],
            });
          }
        }, 100);
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    getPoint(e) {
      this.pointForm = e;
    },
  },

  created() {},

  mounted() {},
};
</script>
<style lang='less' scoped>
.gridPoint {
  display: flex;
  div {
    width: 1.5rem;
    margin-left: 20px;
    display: flex;
    align-items: center;
    span {
      margin-right: 0.05rem;
    }
  }
}
/deep/.ant-btn-primary {
  background-color: rgb(230, 162, 60) !important;
  border: 0px solid #000;
}
</style>